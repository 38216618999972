<template>
	<nav
	class="MapNav"
	>
		<MapBtn
		v-for="(el, i) in navControl"
		:key="i"
		:clickEvent="el.clickEvent"
		:icon="el.icon"
		:color="el.color"
		:tooltip="el.tooltip"
		:disabledStat="el.disabledStat"
		/>
	</nav>
</template>

<script>
import store from "@/store";
import MapBtn from "./Map.btn.vue";
import mapUtils from "../../utils/map/map.utils";
import * as turf from "@turf/turf";

export default {
	name: "MapNav",
	components: {
		MapBtn
	},
	props: [
		"map",
		"draw",
		"isTableDisplayed",
		"tableHeight",
		"measuringTapeContainerId",
		"isMapInReloading",
		"currentZoom"
	],
	emits: ["beforeStyleChange", "styleChanged"],
	data: () => ({
		navControl: [],
		isHelpPopinDisplayed: false,
		measuringTape: false,
	}),
	computed: {
		getMeasuringTapeValue(){
			return this.measuringTape && !this.isMapInReloading;
		}
	},
	watch: {
		isMapInReloading(newVal){
			if(!newVal){
				mapUtils.tapeMeasure.addSourceAndLayers();
				this.map.getCanvas().style.cursor = "pointer";
			}
		}
	},
	methods: {
		/**
     * @description call zoom in mapbox event
     * @returns void
     */
		zoomIn(){
			this.map.zoomIn();
		},
		/**
     * @description call zoom out mapbox event
     * @returns void
     */
		zoomOut(){
			this.map.zoomOut();
		},
		/**
     * @description set new loc to map, jump into it
     * @param {number} x - the latitude coord on map
     * @param {number} y - the longitude coord on map
     * @returns void
     */
		reCenter(x, y){
			this.map.jumpTo({
				center: [x, y],
				zoom: 16
			});
		},
		centerProject(){
			let bounds = store.state.campaign.current.bounds;
			this.map.fitBounds(bounds, {duration: 0, padding: 50});
		},
		orientationToNorth(){
			this.map.resetNorth({duration: 1000});
		},
		/**
     * @description check if geoloc is available, then ask for permission, and get actual device coords to update map loc
     * @returns void
     */
		centerGeoLoc(){
			if("geolocation" in navigator){
				navigator.geolocation.getCurrentPosition(pos => {
					this.reCenter(pos.coords.longitude, pos.coords.latitude);
				});
			}
			else {
				alert(
					"La géolocalisation n'est pas disponible sur cet appareil ou sur ce navigateur"
				);
			}
		},
		changeMapStyle(){
			if(this.isMapInReloading) return;
			this.$emit("beforeStyleChange");
			this.map.getCanvas().style.cursor = "wait";
			let mapStyle = store.state.campaign.current.mapStyle;
			if(mapStyle != "satellite"){
				this.$store.commit("campaign/set_current", {mapStyle: "satellite"});
				this.map.setStyle("mapbox://styles/mapbox/satellite-v9");
			}
			else {
				this.$store.commit("campaign/set_current", {mapStyle: "standard"});
				this.map.setStyle("mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj");
			}
			this.$emit("styleChanged");
		},
		showPolyTutorial(){},
		displayEvents(){
			return true;
		},
		changeMeasuringTape(){
			if(this.isMapInReloading) return;
			this.measuringTape = !this.measuringTape;
			if(!this.measuringTape){
				mapUtils.tapeMeasure.clearDistanceMeasurer();
			}
		},
		init(){
			this.map.getCanvas().addEventListener("keydown", e => {
				e.preventDefault();
				if(e.which === 27){
					if(!this.measuringTape) return;
					this.measuringTape = false;
					mapUtils.tapeMeasure.clearDistanceMeasurer();
				}
			});
			mapUtils.tapeMeasure.initDistanceMeasurer(
				this.map,
				"distance",
				() => this.getMeasuringTapeValue,
				turf
			);
			mapUtils.tapeMeasure.addDistanceMeasurer();
		},
		ReloadMeasureTool(){
			mapUtils.tapeMeasure.addSourceAndLayers();
		}
	},
	/**
   * @description set nav control with custom event, icon and tooltip
   * @returns void
   */
	mounted(){
		this.navControl.push(
			{
				clickEvent: this.orientationToNorth,
				icon: "mdi-compass",
				tooltip: "Orienter vers le nord",
				disabledStat: false,
				color: "secondary"
			},
			{
				clickEvent: this.changeMeasuringTape,
				icon: "mdi-tape-measure",
				tooltip: "Mesurer distance",
				disabledStat: false,
				color: "secondary"
			},
			{
				clickEvent: this.centerProject,
				icon: "mdi-image-filter-center-focus",
				tooltip: "Centrer sur le projet",
				disabledStat: false,
				color: "secondary"
			},
			{
				clickEvent: this.changeMapStyle,
				icon: "mdi-layers",
				tooltip: "Changer le fond de carte",
				disabledStat: false,
				color: "secondary"
			},
			{
				clickEvent: this.centerGeoLoc,
				icon: "mdi-crosshairs-gps",
				tooltip: "Centrer sur ma position",
				disabledStat: false,
				color: "secondary"
			},
			{
				clickEvent: this.zoomIn,
				icon: "mdi-plus-circle-outline",
				tooltip: "Zoomer",
				disabledStat: false,
				color: "secondary"
			},
			{
				clickEvent: this.zoomOut,
				icon: "mdi-minus-circle-outline",
				tooltip: "Dézoomer",
				disabledStat: false,
				color: "secondary"
			}
		);
	},
	created(){
		this.unwatchMap = this.$watch("map", newVal => {
			if(newVal){
				newVal.on("load", () => {
					this.init();
				});
				this.unwatchMap();
			}
		});
	}
};
</script>

<style lang="scss" scoped>
.MapNav {
  position: absolute;
  bottom: 25px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: bottom 0.5s ease;
  z-index: 2;
}
</style>
