<template>
	<div class="coring-diaporamap">
		<v-carousel
		v-model="indexPhoto"
		hide-delimiter-background
		show-arrows-on-hover
		height="100%"
		class="flex"
		@change="resetRotation"
		>
			<v-carousel-item
			v-for="(item, i) in items"
			:key="i"
			height="100%"
			>
				<div class="text-center pb-4 pt-4">
					<h2>Carotte numéro {{ currentCore.number }}</h2>

					<h2>
						{{
							currentCore.corePictures[i]
								? $t(currentCore.corePictures[i].type)
								: $t(pictureTypes[i])
						}}
					</h2>

					<!-- Download all as zip -->
					<v-btn
					class="ma-1 rounded-background btn-download-all"
					text
					@click="downloadCorePictures()"
					icon
					title="Télécharger toutes les photos de la carotte"
					>
						<v-icon color="black">
							mdi-download
						</v-icon>
					</v-btn>
				</div>

				<div
				class="d-flex flex-row absolute mt-14"
				v-if="item.loaded && $hasRight('campaigns.configureCoringAndStart')"
				>
					<!-- Bouton de modification de l'image-->
					<v-btn
					class="ma-1 rounded-background"
					text
					icon
					@click="modifyPictures = true"
					:disabled="isCropping || isModifying"
					title="Modifier l'image"
					>
						<v-icon color="black">
							mdi-image-refresh-outline
						</v-icon>
					</v-btn>

					<!-- Bouton de rotation -->
					<v-btn
					class="ma-1 rounded-background"
					text
					icon
					@click="rotateRight"
					:disabled="isCropping"
					title="Tourner l'image"
					>
						<v-icon color="black">
							mdi-rotate-right-variant
						</v-icon>
					</v-btn>

					<!-- Bouton de crop -->
					<v-btn
					class="ma-1 rounded-background"
					text
					icon
					@click="doCrop"
					:disabled="isModifying"
					title="Rogner l'image"
					>
						<v-icon color="black">
							mdi-crop
						</v-icon>
					</v-btn>
				</div>

				<div style="height: 70%">
					<v-img
					v-if="item.loaded && !isCropping"
					contain
					height="100%"
					:src="item.src"
					:style="`transform: rotate(${rotation}deg);`"
					:id="'image' + indexPhoto"
					/>

					<Cropper
					:src="item.src"
					@change="change"
					v-else-if="item.loaded && isCropping"
					:style="`transform: rotate(${rotation}deg); height: 100%`"
					/>

					<v-card
					flat
					class="d-flex flex-column align-center justify-center"
					style="display: block; height: 100%"
					v-if="item.src === ''"
					color="transparent"
					>
						<h3 class="grey--text text--darken-1">
							La photo n'a pas encore été prise
						</h3>

						<HighButtonSlot @click="showAddPictures = true">
							Ajouter une photo
						</HighButtonSlot>
					</v-card>
				</div>

				<div
				class="tw-absolute tw-buttom-0 tw-flex tw-justify-around tw-w-full tw-mt-[10px]"
				v-if="isModifying"
				>
					<MediumButtonSlot @click="resetRotation">
						Annuler
					</MediumButtonSlot>

					<HighButtonSlot @click="validateModifications">
						Valider
					</HighButtonSlot>
				</div>

				<div
				class="tw-absolute tw-buttom-0 tw-flex tw-justify-around tw-w-full tw-mt-[10px]"
				v-if="isCropping"
				>
					<MediumButtonSlot @click="isCropping = !isCropping">
						Annuler
					</MediumButtonSlot>

					<HighButtonSlot @click="cropImage">
						Valider
					</HighButtonSlot>
				</div>
			</v-carousel-item>
		</v-carousel>

		<v-dialog
		v-model="showAddPictures"
		width="800"
		>
			<v-card>
				<v-card-title>Ajouter une photo</v-card-title>

				<v-card-text>
					<v-file-input
					label="Ajouter une photo"
					show-size
					truncate-length="15"
					@change="previewImageChange"
					v-model="previewImage"
					/>

					<div
					class="flex d-flex align-center justify-center"
					v-if="previewImage !== null"
					>
						<v-img
						:src="previewImageUrl"
						class="preview"
						/>
					</div>
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<MediumButtonSlot
					color="primary"
					text
					@click="
						showAddPictures = false;
						previewImage = null;
					"
					>
						Annuler
					</MediumButtonSlot>

					<v-spacer/>

					<HighButtonSlot
					:disabled="null === previewImage"
					@click="createImage"
					>
						Ajouter
					</HighButtonSlot>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
		v-model="modifyPictures"
		v-if="modifyPictures"
		width="800"
		>
			<v-card>
				<v-card-title>Modifier une photo</v-card-title>

				<v-card-text class="">
					<v-file-input
					label="Modifier une photo"
					show-size
					truncate-length="15"
					@change="previewImageChange"
					v-model="previewImage"
					/>

					<div
					class="flex d-flex align-center justify-center"
					v-if="previewImage !== null"
					>
						<v-img
						:src="previewImageUrl"
						class="preview"
						/>
					</div>
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<MediumButtonSlot
					color="primary"
					text
					@click="
						modifyPictures = false;
						previewImage = null;
					"
					>
						Annuler
					</MediumButtonSlot>

					<MediumButtonSlot
					@click="resetToTechnicianPicture"
					v-if="
						false === currentCore.corePictures[indexPhoto].addedByTechnician
					"
					>
						Supprimer la photo
					</MediumButtonSlot>

					<v-spacer/>

					<MediumButtonSlot
					v-if="hasPictureBeenEdited"
					@click="resetRotationAndCrop"
					>
						Réinitialiser la photo
					</MediumButtonSlot>

					<HighButtonSlot
					:disabled="null === previewImage"
					@click="uploadImage"
					>
						Modifier
					</HighButtonSlot>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import HighButtonSlot from "../slots/buttons/High.button.slot.vue";
import MediumButtonSlot from "../slots/buttons/Medium.button.slot.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
	name: "DiaporamaMap",
	components: { HighButtonSlot, MediumButtonSlot, Cropper },
	props: {
		map: {
			type: Object
		},
		isOpen: {
			type: Boolean
		},
		decoration: {
			type: Boolean,
			default: false
		},
		cores: {
			type: Array
		},
		coreId: {
			type: Number
		},
		coringCampaign:{

		}
	},
	data() {
		return {
			items: [],
			avatar: null,
			markers: [],
			indexPhoto: 0,
			shouldReload: true,
			pictureTypes: [
				"front",
				"core",
				"hole",
				"under",
				"other",
				"other_2",
				"other_3"
			],
			rotation: 0,
			isModifying: false,
			isCropping: false,
			imageCoordinates: {},
			showAddPictures: false,
			currentCore: {},
			modifyPictures: false,
			previewImage: null,
			previewImageUrl: null,
			hasPictureBeenEdited: false
		};
	},
	computed: {},
	watch: {
		indexPhoto() {
			// dès qu'on change de page, on vérifie si la photo a été modifiée
			this.checkPictureConfig();
		},
		rotation() {
			// dès qu'on change la rotation d'une image on revérifie pour changer la valeur de hasPictureBeenEdited
			this.checkPictureConfig();
		},
		imageCoordinates() {
			// dès qu'on crop une image on revérifie pour changer la valeur de hasPictureBeenEdited
			this.checkPictureConfig();
		},
		coreId() {
			if (this.isOpen) {
				this.load();
			}
		},
		isOpen: {
			handler(newVal) {
				if (newVal) {
					this.load();
				}
			},
			deep: false
		}
	},
	methods: {
		async getImages() {
			// on va piocher dans le store la carotte correspondant à this.coreId
			const corePicturesInStore =
        this.$store.state.coreCampaignPictures.coresPictures[this.coreId];

			// si elle existe dans le store, donc différente de undefined, récupérer ses images pour les stocker dans this items
			if (corePicturesInStore != undefined) {
				this.items = corePicturesInStore;
			}
			// sinon faire l'appel à l'api et la stocker dans le store
			else {
				this.items = [];
				let condition = 7;

				// variable qui contiendra le find sur currentCore.CorePictures
				let filteredCurrentCore = null;
				// variable qui récupérera le resultat du find à chaque boucle for pour les réassigner à this.currenCore.CorePictures
				let currentCore = [];

				if (this.currentCore.status === "cancelled") condition = 1;
				for (let index = 0; index < condition; index++) {
					filteredCurrentCore = this.currentCore.corePictures.find(
						corePicture => corePicture.type === this.pictureTypes[index]
					);
					currentCore.push(filteredCurrentCore);
					if (filteredCurrentCore !== undefined) {
						const img = await this.$api.documents.download(
							filteredCurrentCore.path.startsWith(this.coringCampaign.workspace.site.organization.id)?
								filteredCurrentCore.path :
								(
									this.coringCampaign.workspace.site.organization.id +
									"/" + 
									filteredCurrentCore.path
								)
						);
						this.$set(this.items, index, { src: "", loaded: false });
						const url = window.URL.createObjectURL(img);
						this.items[index].src = url;
						this.items[index].loaded = true;
					} else if (this.currentCore.status !== "results_acquired")
						this.$set(this.items, index, { src: "", loaded: false });
				}

				this.currentCore.corePictures = currentCore;

				// on stocke les images dans le store pour actualiser l'affichage sans rafraichir
				this.storePictures(this.items);

				this.checkPictureConfig();
			}
		},
		async downloadCorePictures() {
			const response = await this.$api.corePictures.downloadAllPictures(
				this.currentCore.id
			);

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", this.currentCore.businessId + ".zip");
			document.body.appendChild(link);
			link.click();
		},
		moveAvatar(coords) {
			if (this.avatar) {
				this.avatar.remove();
				this.avatar = null;
			}
			const icon = document.createElement("i");
			icon.className = "large mdi mdi-truck avatar";
			icon.style.fontSize = "xx-large";

			// Add markers to the map.
			this.avatar = new mapboxgl.Marker(icon).setLngLat(coords).addTo(this.map);
			this.map.jumpTo({
				center: [coords[0], coords[1]],
				zoom: 16,
				pitch: 60
			});
		},
		load() {
			this.currentCore = this.cores.find(core => core.id == this.coreId);
			this.getImages().then(() => {
				this.indexPhoto = 0;
			});
		},
		rotateRight() {
			this.rotation += 90;
			this.isModifying = true;
			if (this.rotation === 360) {
				this.resetRotation();
			}
		},
		resetRotation() {
			this.rotation = 0;
			this.isModifying = false;
			this.isCropping = false;
		},
		async validateModifications() {
			let response = await this.$api.corePictures.modify(
				this.currentCore.corePictures[this.indexPhoto].id,
				{
					rotate:
            this.rotation +
            this.currentCore.corePictures[this.indexPhoto].imageConfig
            .rotationAngle
				}
			);
			this.currentCore.corePictures[this.indexPhoto].imageConfig.rotationAngle =
        response.imageConfig.rotationAngle;
			this.isModifying = false;
			this.reloadImage();
		},
		change({ coordinates }) {
			this.imageCoordinates = coordinates;
		},
		doCrop() {
			this.isCropping = !this.isCropping;
		},
		async cropImage() {
			await this.$api.corePictures.modify(
				this.currentCore.corePictures[this.indexPhoto].id,
				{
					x: this.imageCoordinates.left,
					y: this.imageCoordinates.top,
					width: this.imageCoordinates.width,
					height: this.imageCoordinates.height
				}
			);
			this.isCropping = false;
			this.reloadImage();
		},
		async resetRotationAndCrop() {
			this.modifyPictures = false;
			await this.$api.corePictures.modify(
				this.currentCore.corePictures[this.indexPhoto].id,
				{
					rotate: 0
				}
			);
			await this.$api.corePictures.modify(
				this.currentCore.corePictures[this.indexPhoto].id,
				{
					x: null,
					y: null,
					width: null,
					height: null
				}
			);
			this.rotation = 0;
			this.imageCoordinates = {};

			this.reloadImage();
			this.hasPictureBeenEdited = false;
		},
		async reloadImage() {
			let itemsCopy = [...this.items];

			const document = await this.$api.corePictures.get(
				this.currentCore.corePictures[this.indexPhoto].id
			);
			this.$set(itemsCopy, this.indexPhoto, { src: "", loaded: false });
			const url = window.URL.createObjectURL(document);
			this.rotation = 0;
			itemsCopy[this.indexPhoto].src = url;
			itemsCopy[this.indexPhoto].loaded = true;

			this.storePictures(itemsCopy);
		},
		previewImageChange() {
			this.previewImage !== null
				? (this.previewImageUrl = URL.createObjectURL(this.previewImage))
				: (this.previewImageUrl = null);
		},
		async uploadImage() {
			this.modifyPictures = false;
			const formData = new FormData();
			formData.append("coreId", this.currentCore.id);
			formData.append("file", this.previewImage);

			let image = await this.$api.corePictures.upload(formData, {
				coreId: this.currentCore.id,
				type: this.pictureTypes[this.indexPhoto],
				id: this.currentCore.corePictures[this.indexPhoto].id //corePIctureId
			});
			this.currentCore.corePictures[this.indexPhoto].id = image.id;
			this.currentCore.corePictures[this.indexPhoto].addedByTechnician = false;
			this.previewImage = null;
			this.resetRotationAndCrop();
			this.reloadImage();
		},
		createImage() {
			const formData = new FormData();
			formData.append("coreId", this.currentCore.id);
			formData.append("file", this.previewImage);

			this.$api.corePictures
			.upload(formData, {
				coreId: this.currentCore.id,
				type: this.pictureTypes[this.indexPhoto]
			})
			.then(image => {
				// this items étant alimenté par le store, on ne peut pas le réassigner sans passer par ma méthode commit de vuex
				// donc on en fait une copie avec le spread operator
				let itemsCopy = [...this.items];
				this.$api.corePictures.get(image.id).then(document => {
					this.$set(itemsCopy, this.indexPhoto, { src: "", loaded: false });
					const url = window.URL.createObjectURL(document);
					itemsCopy[this.indexPhoto].src = url;
					itemsCopy[this.indexPhoto].loaded = true;

					// puis on commit cette copie de this items dans le store
					this.storePictures(itemsCopy);
					this.currentCore.corePictures[this.indexPhoto] = {
						addedByTechnician: false
					};

					this.showAddPictures = false;
				});
			});
		},
		storePictures(newPictures) {
			// méthode pour enregistrer dans le store une nouvelle image ou update une image déjà ajoutée pour la currentCore
			this.$store.commit("coreCampaignPictures/storePictures", {
				id: this.coreId,
				pictures: newPictures
			});

			// et on réassigne this items avec le store pour mettre à jour l'affichage des photos sans refresh
			this.items =
        this.$store.state.coreCampaignPictures.coresPictures[this.coreId];
		},
		async resetToTechnicianPicture() {
			this.modifyPictures = false;
			await this.$api.corePictures.remove(
				this.currentCore.corePictures[this.indexPhoto].id
			);
			//call api récupération de la photo tech
			let tempCore = await this.$api.cores.get(this.currentCore.id);
			let newCoreInfos = tempCore.corePictures.find(
				picture =>
					picture.type == this.currentCore.corePictures[this.indexPhoto].type
			);
			this.currentCore.corePictures[this.indexPhoto].id = newCoreInfos.id;
			this.currentCore.corePictures[this.indexPhoto].addedByTechnician =
        newCoreInfos.addedByTechnician;

			this.reloadImage();
		},
		checkPictureConfig() {
			// méthode pour vérifier si des modifications ont été effectuées sur une photo et modifier hasPictureBeenEdited en fonction
			// pour ensuite conditionner l'affichage du bouton "Annuler les modifications" en fonction de hasPictureBeenEdited
			if (this.currentCore.corePictures[this.indexPhoto] !== undefined) {
				if (
					this.currentCore.corePictures[this.indexPhoto].imageConfig
					.rotationAngle !== 0
				) {
					this.hasPictureBeenEdited = true;
				} else if (
					this.currentCore.corePictures[this.indexPhoto].imageConfig
					.cropHeight !== null ||
          this.currentCore.corePictures[this.indexPhoto].imageConfig
          .cropWidth !== null ||
          this.currentCore.corePictures[this.indexPhoto].imageConfig.cropX !==
            null ||
          this.currentCore.corePictures[this.indexPhoto].imageConfig.cropX !==
            null
				) {
					this.hasPictureBeenEdited = true;
				} else {
					this.hasPictureBeenEdited = false;
				}
			}
		}
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
div.coring-diaporamap {
  background-color: #f1f1f1;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 3;

  .btn-download-all {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .avatar {
    color: $brown;
  }
  .mdi-circle {
    color: #412420 !important;
  }
  .v-btn--active {
    color: #412420 !important;
    background-color: rgba(65, 36, 36, 0.3) !important;
  }
  .v-window__next {
    top: 45% !important;
    right: -10px !important;
  }
  .v-window__prev {
    top: 45% !important;
    left: -10px !important;
  }
  .v-carousel__controls > .v-item-group {
    max-width: 308px;
    width: 308px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0 8px;

    > button {
      margin: 0 0;
    }
  }
  .rounded-background {
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  .absolute {
    position: absolute;
    z-index: 1;
    top: 7%;
    right: 11%;
  }
  .preview {
    max-width: 20%;
  }
}
</style>
