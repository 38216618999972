import MAPLAYER_TYPE from "./map/maplayertype.const";
import SYMBOL_TYPE from "./map/symboltype.const";

const getfilterConfig = function(
	geomType, 
	symbolType, 
	symbolInfo, 
	labelColumn,
	orgGeomType,
	offset
){
	let config = {};
	switch (geomType){    
		case MAPLAYER_TYPE.POINT: //Point
			if(symbolType == SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DIRECTION){
				config.type = "symbol";
				config.layout = {
					"icon-image": [
						"concat", 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.DIRECTION, 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR, 
						["get", "col"]
					],
					"icon-size": ["get", "tns"],
					"icon-allow-overlap": true,
					"icon-rotate": ["get", symbolInfo],
					"icon-rotation-alignment": "map"
				};
			}
			else if(symbolType == SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PR){
				config.type = "symbol";
				config.layout = {
					"icon-image": [
						"concat", 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.PR, 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR, 
						["get", "col"]
					],
					"icon-size": ["get", "tns"],
					"icon-allow-overlap": true
				};
			}
			else if(symbolType === SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_BRANDS_ICON ||
				symbolType === SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_REGULAR_ICON ||
				symbolType === SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.FONTAWESOME_SOLID_ICON
			){
				config.type = "symbol";
				config.layout = {
					"icon-image": [
						"concat", 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.FONTAWESOME,
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR, 
						SYMBOL_TYPE.getFontAwesomeClass(symbolType), 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR,
						["get", "sym"], 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR, 
						["get", "col"]
					], 
					"icon-size": ["get", "tns"],
					"icon-allow-overlap": true,
					"icon-rotate": ["get", symbolInfo]
				};
			}
			else if(symbolType === SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.VERTICAL_SIGNAL){
				config.type = "symbol";
				config.layout = {
					"icon-image": [
						"concat", 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.VERTICAL_SIGNAL,
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR, 
						["get", "sym"]
					], 
					"icon-size": ["get", "tns"],
					"icon-allow-overlap": true,
					"icon-rotate": ["get", symbolInfo]
				};
			}
			else if(symbolType === SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.PERSONAL_ICON){
				config.type = "symbol";
				config.layout = {
					"icon-image": [
						"concat", 
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.PERSONAL_ICON,
						SYMBOL_TYPE.MAPLAYER_SYMBOL_INDENTIFICATION.SEPARATOR, 
						["get", "sym"]
					], 
					"icon-size": ["get", "tns"],
					"icon-allow-overlap": true,
					"icon-rotate": ["get", symbolInfo]
				};
			}
			else {
				config.type = "circle";
				config.layout = {};
				config.paint = {
					"circle-radius": ["get", "tns"],
					"circle-color": ["get", "col"],
					"circle-opacity": 1
				};
			}
			break;
		case MAPLAYER_TYPE.POLYGON:
			config.type = "fill";
			config.layout = {};
			config.paint = {
				"fill-color": ["get", "col"],
				"fill-opacity": 0.7
			};
			break;
		case MAPLAYER_TYPE.POLYGON3D: //3D Polygon
			config.type = "fill-extrusion";
			config.layout = {};
			config.paint = {
				"fill-extrusion-color": ["get", "col"],
				"fill-extrusion-height": [
					"interpolate",
					["linear"],
					["zoom"],
					12.9,
					0,
					12.9 + 0.05,
					["get", "ext"]
				],
				"fill-extrusion-base": [
					"interpolate",
					["linear"],
					["zoom"],
					12.9,
					0,
					12.9 + 0.05,
					0
				],
				"fill-extrusion-opacity": 0.7
			};
			break;
		case MAPLAYER_TYPE.LINESTRING: //linestring MultiColor
			config.type = "line";
			// config.minzoom = 1;
			// config.maxzoom = 13;
			config.layout = {"line-cap": "round"};
			config.paint = {
				"line-color": ["get", "col"],
				"line-width": ["get", "tns"],
				"line-opacity": 1
			};
			break;
		case MAPLAYER_TYPE.LABEL:
			config.type = "symbol";
			config.layout = {
				"text-field": "{" + labelColumn + "}",
				"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
				"text-size": 12,
				"text-offset": [0, offset]
			};
			if(orgGeomType === MAPLAYER_TYPE.LINESTRING){
				config.layout["symbol-placement"] = "line";
				config.layout["text-anchor"] = "center";
			}
			break;
		default:
			config.type = "line";
			config.minzoom = 1;
			config.maxzoom = 13;
			config.layout = {"line-cap": "round"};
			config.paint = {
				"line-color": ["get", "col"],
				"line-width": ["get", "tns"],
				"line-opacity": 1
			};
			break;
	}
	return config;
};

export default {getfilterConfig};
