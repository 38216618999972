export default Object.freeze({  
	POINT: 2,
	POLYGON3D: 3,
	LINESTRING: 4,
	POLYGON: 5,
	MULTI_POINT: 6,
	MULTI_LINESTRING: 7,
	MULTI_POLYGON: 8,
	LABEL: 5000
});
