<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		:title="$cap(coringCampaign.name)"
		sub-title="Carte"
		:toBackButton="{ name: 'project', params: { id: coringCampaign.workspace.id } }"
		>
			<EngineerQuestion
			v-if="!isGuest && false === $store.state.user.current.isInternal"
			:_res="1550"
			/>

			<ButtonSlot
			:_to="{
				name: dashboardComponentName,
				params: { id: $route.params.id }
			}"
			_icon="mdi-file-document-outline"
			>
				Tableau de bord
			</ButtonSlot>
		</HeaderSlot>

		<v-card
		class="tw-grow tw-overflow-hidden tw-w-full"
		outlined
		>
			<CoringResultMap
			:coringCampaign="coringCampaign"
			:cores="cores"
			:layersList="layersList"
			:filters="filters"
			:selectedLayers="selectedLayers"
			:AUPrsLayerInfo="AUPrsLayerInfo"
			:RNPrsLayerInfo="RNPrsLayerInfo"
			:RDPrsLayerInfo="RDPrsLayerInfo"
			:filterValues="filterValues"
			:changeFilter="changeFilter"
			@closeMenu="closeMenu"
			>
				<CoringMapMenu
				ref="menu"
				v-if="layersList.length"
				:layersList="layersList"
				@layerSelected="layerSelected"
				@reloadLayers="reloadLayers"
				/>

				<MapFiltre
				:layersLegend="layersLegend"
				:selectedLayers="selectedLayers"
				@filterChanged="changeFilter"
				/>
			</CoringResultMap>
		</v-card>
	</div>
</template>

<script>
import CoringResultMap from "../../components/coring/map/CoringResult.map.vue";
import CoringMapMenu from "../../components/coring/map/Coring.map.menu.vue";
import MapFiltre from "../../components/map/Map.filtre.vue";
import EngineerQuestion from "../../components/popup/EngineerQuestion";

import auth from "../../service/auth";

export default {
	name: "CoringCampaignMap",
	components: {
		CoringResultMap, CoringMapMenu, MapFiltre, EngineerQuestion
	},
	data(){
		return {
			dashboardComponentName: "",
			isGuest: false,
			coringCampaign: {name: "", workspace: {id: ""}},

			layersList: [],
			selectedLayers: [],
			filters: [],

			isLoading: true,
			isStyleLoading: true,

			cores: [],

			layersLegend: [],
			AUPrsLayerInfo: null,
			RNPrsLayerInfo: null,
			RDPrsLayerInfo: null,
			filterValues: []
		};
	},
	methods: {
		reloadLayers(data){
			this.filterValues = data;
		},
		changeFilter(newVal){
			let filterChanged = newVal.map(layerLegend => {
				let filters = layerLegend.legends
				.filter(el => el.selected)
				.map(legend => {
					if(legend.selected){
						if(legend.valueExact && legend.valueExact.length > 0){
							let allFilters = ["any"];
							legend.valueExact.forEach(val => {
								allFilters.push([
									"==",
									["to-string", ["get", layerLegend.column]],
									["to-string", `${val}`]
								]);
							});
							return allFilters;
						}
						else {
							let filter = ["all"];
							filter.push([
								legend.excludeMin ? ">" : ">=",
								["get", layerLegend.column],
								legend.min
							]);
							filter.push([
								legend.excludeMax ? "<" : "<=",
								["get", layerLegend.column],
								legend.max
							]);
							return filter;
						}
					}
				});
				return {id: layerLegend.id, filters: filters};
			});
			this.filters = filterChanged;
		},
		closeMenu(isOpen){
			this.$refs.menu.changeExpansionPanel(isOpen);
		},
		getLayersList(campaignId){
			this.$api.coringMapLayers.findByCampaign(campaignId).then(async layers => {
				this.layersList = layers;
				this.isLoading = false;
				this.layersLegend = await this.getLayersLegends(this.layersList);
			});
		},
		async getLayersLegends(layers){
			let layersLegends = [];
			let getLayerLegend = async layer => {
				const legends = await this.$api.coringMapLayers.getLegends(layer.id);
				return {
					id: layer.id + "_Id",
					name: layer.name,
					legends,
					column: layer.layerColumn
				};
			};
			for(let i = 0; i < layers.length; i++){
				let layer = layers[i];
				if(!layer.isContainer){
					let legendLayer = await getLayerLegend(layer);
					if(null !== legendLayer){
						layersLegends.push(legendLayer);
					}
				}
				else {
					let childLayersLegends = await this.getLayersLegends(layer.children);
					if(childLayersLegends.length > 0){
						layersLegends.push(...childLayersLegends);
					}
				}
			}
			return layersLegends;
		},
		layerSelected(selectedLayers){
			this.selectedLayers = selectedLayers;
		}
	},
	mounted(){
		this.isGuest = auth.isLoggedGuest();
		this.dashboardComponentName =
      this.isGuest && !auth.isLogged()
      	? "coring-campaign-dashboard-guest"
      	: "coring-campaign-dashboard";

		this.$api.coringCampaigns.findById(this.$route.params.id).then(campaign => {
			this.coringCampaign = campaign;
			this.getLayersList(campaign.id);

			this.$api.campaigns.getData(this.$route.params.id, "map").then(data => {
				this.cores = data.map(c => {
					let core = {...c};
					core.coordinates = {
						lng: c.coordinates[0],
						lat: c.coordinates[1]
					};
					core.corePictures = c.pictures;
					return core;
				});
			});
		})
		.catch(() => {
			this.isLoading = false;
		});

		this.$api.coringMapLayers.getCommonPRsLayer("AU PR").then(layer => {
			this.AUPrsLayerInfo = layer;
		});
		this.$api.coringMapLayers.getCommonPRsLayer("RN PR").then(layer => {
			this.RNPrsLayerInfo = layer;
		});
		this.$api.coringMapLayers.getCommonPRsLayer("RD PR").then(layer => {
			this.RDPrsLayerInfo = layer;
		});
	}
};
</script>

<style lang="scss">
.corinCampaignMap {
  padding: 15px;
  .coring_campaign__header {
    align-items: center;
    display: flex;
    margin-top: 14px;
  }
}
.map_container {
  border-radius: 4px !important;
  overflow: hidden;
  position: relative;
  margin: 0 8px 0 8px;
  height: 85vh;
}
.mapboxgl-canvas {
  outline: none;
}
</style>
