<template>
	<v-tooltip left>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
			small
			max-width="40"
			height="40"
			@click="clickEvent"
			v-bind="attrs"
			v-on="on"
			:color="activated?'#d3d3d3':''"
			>
				<img
				class="btn-image" 
				:class="activated?'activated':''"
				:src="icon"
				/>
			</v-btn>
		</template>

		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
export default {
	name: "MapIconBtn",
	props: [
		"activated", "clickEvent", "icon", "tooltip"
	]  
};
</script>

<style lang="scss" scoped>
.btn-image {
  height: 23px;
  width: 23px;
}

.activated {
  background-color: #ffffff00;
}

button {
  width: 40px !important;
  min-width: 40px !important;
  aspect-ratio: 1/1 !important;
}
</style>
