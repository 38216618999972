<template>
	<div id="CoringResult_Map">
		<DiaporamaMap
		:class="{
			diaporama_map_diapo_on: isDiaporamaOpen,
			diaporama_map_diapo_off: !isDiaporamaOpen
		}"
		:map="map"
		:photosGeoJson="photosData"
		:isOpen="isDiaporamaOpen"
		:decoration="false"
		:cores="cores"
		:coreId="currentCoreId"
		:coringCampaign="coringCampaign"
		/>

		<!-- <div id="mapContainer" class="auscultation_map"> -->
		<div
		id="mapContainer"
		:class="{
			auscultation_map_diapo_on: isDiaporamaOpen,
			auscultation_map: !isDiaporamaOpen
		}"
		>
			<slot/>

			<div
			id="geocoder"
			class="geocoder"
			ref="geocoder"
			/>

			<map-nav
			:map="map"
			:draw="draw"
			:tableHeight="tableHeight"
			@styleChanged="styleChanged"
			:measuringTapeContainerId="'distance'"
			:isMapInReloading="isMapInReloading"
			:currentZoom="lastZoom"
			/>

			<map-prs-nav
			:map="map"
			:draw="draw"
			@displayAUPrs="displayAUPrs"
			@displayRNPrs="displayRNPrs"
			@displayRDPrs="displayRDPrs"
			:AUPrsLayerInfo="AUPrsLayerInfo"
			:RNPrsLayerInfo="RNPrsLayerInfo"
			:RDPrsLayerInfo="RDPrsLayerInfo"
			:measuringTapeContainerId="'distance'"
			:currentZoom="lastZoom"
			ref="prsNav"
			/>

			<v-btn
			depressed
			class="diaporama_btn"
			color="#f1f1f1"
			v-if="isDiaporamaButtonDisplayed"
			@click="switchDisplayDiapoBtn(!isDiaporamaOpen)"
			>
				{{ isDiaporamaOpen ? "Fermer diaporama" : "Afficher diaporama" }}
				<v-icon color="black">
					{{
						isDiaporamaOpen ? "mdi-chevron-up" : "mdi-chevron-down"
					}}
				</v-icon>
			</v-btn>

			<div class="bottom-left-gizmo">
				<div
				id="distance"
				class="distance-container"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import auth from "../../../service/auth";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import configUtils from "../../../utils/config.utils";
import MapPopup from "../Coring.map.popup.vue";
import store from "@/store";
import * as turf from "@turf/turf";
import mapNav from "../../map/Map.nav.vue";
import mapPrsNav from "../../map/Map.prs.nav.vue";
import DiaporamaMap from "../../map/Diaporama.map.vue";
import MAPLAYER_STATUS from "../../../utils/map/maplayerstatus.const";
import MAPLAYER_TYPE from "../../../utils/map/maplayertype.const";
import SYMBOL_TYPE from "../../../utils/map/symboltype.const";
import MAPLAYER_ZOOM from "../../../utils/map/maplayerzoom.const";
import {LayerUtils} from "../../../utils/map/map.utils";
import Vue from "vue";

export default {
	name: "AuscultationMap",
	components: {mapNav, mapPrsNav, DiaporamaMap},
	props: [
		"coringCampaign",
		"cores",
		"selectedLayers",
		"filters",
		"layersList",
		"AUPrsLayerInfo",
		"RNPrsLayerInfo",
		"RDPrsLayerInfo",
		"filterValues"
	],
	data(){
		return {
			accessToken:
        "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
			location: null,
			map: {},
			lastZoom: 12,
			draw: {},
			mapLoaded: false,
			defaultCenter: [2.213749, 46.227638],
			photosData: {},
			tableHeight: 250,
			currentStyle: 0,
			isMapInReloading: false,
			isDiaporamaOpen: false,
			isDiaporamaButtonDisplayed: false,
			popup: null,
			currentCoreId: 0,
			AUPrsLayerUtils: null,
			RNPrsLayerUtils: null,
			RDPrsLayerUtils: null
		};
	},
	beforeMounted(){},
	computed: {
		updateEvent(){
			return this.mapLoaded && this.selectedLayers;
		},
		filterEvent(){
			return this.mapLoaded && this.filters;
		},
		resetEvent(){
			return this.layersList && this.currentStyle;
		},
		layersById(){
			let layersIndexedById = {};
			this.layersList.forEach(layer => {
				let layersAndChildWithId = this.getLayerAndChildWithIds(layer);
				Object.assign(layersIndexedById, layersAndChildWithId);
			});
			return layersIndexedById;
		}
	},
	watch: {
		isDiaporamaOpen(newVal){
			let canvas = this.map.getCanvas();
			if(!newVal){
				this.isDiaporamaOpen = false;
				canvas.style.width = "100%";
			}
			else {
				this.isDiaporamaOpen = true;
				canvas.style.width = "100%";
			}
			setTimeout(() => this.map.resize(), 10);
		},
		filterEvent: {
			handler(){
				if(this.mapLoaded){
					this.filters.forEach(el => {
						this.map.setFilter(el.id, ["any", ...el.filters]);
						let layerId = el.id;
						layerId = layerId.substr(0, layerId.length - 3);
						let layerLabelId = layerId + "_Label";
						if(this.map.getLayer(layerLabelId)){
							this.map.setFilter(layerLabelId, ["any", ...el.filters]);
						}
					});
				}
			},
			deep: true
		},
		updateEvent: function(){
			if(this.mapLoaded){
				this.reload3DLayers(this.layersList);
				this.updateLayers(this.selectedLayers, this.layersList);
				let visibleLayers = this.getVisibleDataLayersIdList(this.layersList);
				if(visibleLayers.length == 0){
					visibleLayers = this.getDataLayersIdList(this.layersList);
				}
				this.updateLayerBounds(visibleLayers, true);
			}
		},
		resetEvent: {
			handler(){
				if(this.layersList.length > 0){
					this.resetLayers();
					this.$refs.prsNav.updateMap();
				}
			},
			deep: true
		},
		filterValues: function(){
			this.reloadLayers();
		}
	},
	methods: {
		reloadLayers(){
			this.layersList.forEach(() => {
				Object.keys(this.map.style._sourceCaches).forEach(key => {
					let source = this.map.style._sourceCaches[key];
					source.clearTiles();
					source.update(this.map.transform);
				});
			});
			this.map.triggerRepaint();
		},
		flyTo(coordinates, zoom = 15){
			this.map.jumpTo({
				center: coordinates,
				essential: true,
				zoom: zoom
			});
		},
		getLayerId(layer){
			return layer.id + "_Id";
		},
		getSourceId(layer){
			return layer.id + "_Source";
		},
		getLabelLayerId(layer){
			return layer.id + "_Label";
		},
		getLabelSourceId(layer){
			return layer.id + "_LabelSource";
		},
		removeLayer(layer){
			let layerId = this.getLayerId(layer);
			this.map.removeLayer(layerId);
			if(layer.displayColumn){
				let labelLayerId = this.getLabelLayerId(layer);
				this.map.removeLayer(labelLayerId);
			}
		},
		getLayerAndChildWithIds(layer){
			let layersWithId = {};
			layersWithId[layer.id] = layer;
			layer.children.forEach(child => {
				let childLayersWithId = this.getLayerAndChildWithIds(child);
				Object.assign(layersWithId, childLayersWithId);
			});
			return layersWithId;
		},
		async mapInit(){
			let mapStyle = "mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj";
			if(store.state.campaign.current.mapStyle === "satellite") mapStyle = "mapbox://styles/mapbox/satellite-v9";

			mapboxgl.accessToken = this.accessToken;

			let center = this.defaultCenter;
			if("geolocation" in navigator){
				navigator.geolocation.getCurrentPosition(pos => {
					center = [pos.coords.longitude, pos.coords.latitude];
				});
			}
			else if(store.state.campaign.current.center) center = store.state.campaign.current.center;

			const isGuest = auth.isLoggedGuest();
			const isLogged = auth.isLogged();

			const authorization =
        !isLogged && isGuest ? auth.getGuestToken() : "Bearer " + auth.getJwt();

			this.map = new mapboxgl.Map({
				container: "mapContainer",
				style: mapStyle,
				center,
				zoom: 5,
				transformRequest: (url, resourceType) => {
					if(
						resourceType === "Tile" &&
            url.startsWith(process.env.VUE_APP_BASE_URL)
					){
						return {
							url: url,
							headers: {Authorization: authorization}
						};
					}
				}
			});

			let geocoder = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl: mapboxgl,
				placeholder: this.$t("search"),
				flyTo: {duration: 0}
			});
			this.$refs["geocoder"].appendChild(geocoder.onAdd(this.map));

			const that = this;
			this.map.on("load", function(){});
			this.map.on("style.load", function(){
				that.currentStyle = 1 - that.currentStyle;
			});

			this.map.on("zoom", () => {
				let currentZoom = that.map.getZoom();
				that.reload3DLayers(that.layersList);
				that.lastZoom = currentZoom;
			});
			this.lastZoom = this.map.getZoom();
		},
		centerlizeOnFeature(featureData){
			const center = turf.center(featureData);
			this.flyTo(center.geometry.coordinates, 12);
			this.$store.commit("campaign/set_current", {
				center: center.geometry.coordinates
			});
		},
		async updateLayerBounds(visibleLayers, centerlized = true){
			let bounds = null;
			if(visibleLayers && visibleLayers.length > 0){
				let compaignIdList = [];
				visibleLayers.forEach(layerId => {
					let campaignId = this.layersById[layerId].campaignId;
					if(campaignId){
						compaignIdList.push(campaignId);
					}
				});
				let boundingBox = await this.$axios.get(
					"coring-campaigns/" +
            this.coringCampaign.id +
            "/map-layers/bounding-box?campaign-list=" + compaignIdList.join(",")
				);
				if(
					null !== boundingBox &&
          null !== boundingBox.data &&
          Object.keys(boundingBox.data).length !== 0
				){
					bounds = turf.bbox(boundingBox.data);
					if(centerlized){
						this.map.fitBounds(bounds, {duration: 0});
					}
				}
			}
			if(null !== bounds){
				this.$store.commit("campaign/set_current", {
					bounds: bounds
				});
			}
		},
		addCommonLayer(layer){
			if(layer){
				let commonLayerUtils = new LayerUtils(
					this.coringCampaign,
					this.map,
					layer,
					`${process.env.VUE_APP_BASE_URL}map-layers/{map-layer-id}/tiles/{z}_{x}_{y}.pbf`
				);
				this.handleLabelPointerClick(this.map, layer);
				return commonLayerUtils;
			}
			return null;
		},
		displayAUPrs(showed){
			if(this.AUPrsLayerInfo){
				if(!this.AUPrsLayerUtils){
					this.AUPrsLayerUtils = this.addCommonLayer(this.AUPrsLayerInfo);
				}
				if(showed){
					this.AUPrsLayerUtils.showLayer();
				}
				else {
					this.AUPrsLayerUtils.hideLayer();
				}
			}
		},
		displayRNPrs(showed){
			if(this.RNPrsLayerInfo){
				if(!this.RNPrsLayerUtils){
					this.RNPrsLayerUtils = this.addCommonLayer(this.RNPrsLayerInfo);
				}
				if(showed){
					this.RNPrsLayerUtils.showLayer();
				}
				else {
					this.RNPrsLayerUtils.hideLayer();
				}
			}
		},
		displayRDPrs(showed){
			if(this.RDPrsLayerInfo){
				if(!this.RDPrsLayerUtils){
					this.RDPrsLayerUtils = this.addCommonLayer(this.RDPrsLayerInfo);
				}
				if(showed){
					this.RDPrsLayerUtils.showLayer();
				}
				else {
					this.RDPrsLayerUtils.hideLayer();
				}
			}
		},
		addMapLayer(layer){
			let layerId = this.getLayerId(layer);
			let currentZoom = this.map.getZoom();
			let tileSource = "tile";
			let geomType = layer.geomType;
			let symbolType = layer.symbolType;
			let symbolInfo = layer.symbolInfo;
			if(geomType == MAPLAYER_TYPE.POLYGON3D){
				if(currentZoom < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL){
					if(MAPLAYER_TYPE.LINESTRING === layer.orgGeomType){
						tileSource = "linestring";
					}
					else {
						tileSource = "polygon";
					}
					geomType = MAPLAYER_TYPE.LINESTRING;
				}
				else {
					tileSource = "3D";
					geomType = MAPLAYER_TYPE.POLYGON3D;
				}
			}

			let config = configUtils.getfilterConfig(
				geomType,
				symbolType,
				symbolInfo,
				layer.displayColumn,
				geomType,
				0
			);

			if(this.selectedLayers.indexOf(layerId) !== -1){
				config.layout["visibility"] = "visible";
			}
			else {
				config.layout["visibility"] = "none";
			}

			this.map.addLayer({
				id: layerId,
				source: this.getSourceId(layer),
				"source-layer": tileSource,
				...config
			});

			if(layer.displayColumn){
				config = configUtils.getfilterConfig(
					MAPLAYER_TYPE.LABEL,
					SYMBOL_TYPE.MAPLAYER_SYMBOL_TYPE.DEFAULT,
					null,
					layer.displayColumn,
					geomType,
					0
				);
				if(this.selectedLayers.indexOf(layerId) !== -1){
					config.layout["visibility"] = "visible";
				}
				else {
					config.layout["visibility"] = "none";
				}
				this.map.addLayer({
					id: this.getLabelLayerId(layer),
					source: this.getSourceId(layer),
					"source-layer": tileSource,
					...config
				});
			}
			this.handlePointerClick(this.map, layer);
		},
		async initLayers(list){
			let initLayer = layer => {
				let tileTpl = `${process.env.VUE_APP_BASE_URL}coring-campaigns/{campaign-id}/map-layers/{map-layer-id}/tiles/{z}_{x}_{y}.pbf`;
				let sourceId = this.getSourceId(layer);
				let existedSource = this.map.getSource(sourceId);
				if(!existedSource){
					this.map.addSource(sourceId, {
						type: "vector",
						tiles: [
							tileTpl
							.replace("{campaign-id}", layer.campaignId)
							.replace("{map-layer-id}", layer.id)
						],
						minzoom: layer.minZoom ? layer.minZoom : 0,
						maxzoom: layer.maxZoom ? layer.maxZoom : 30
					});
				}
				let layerId = this.getLayerId(layer);
				let existedLayer = this.map.getLayer(layerId);
				if(existedLayer){
					this.removeLayer(layer);
				}
				this.addMapLayer(layer);
			};
			for(const layer of list){
				if(layer.isContainer){
					await this.initLayers(layer.children);
				}
				else {
					initLayer(layer);
				}
			}
			if(this.mapLoaded){
				this.filters.forEach(el => {
					this.map.setFilter(el.id, ["any", ...el.filters]);
				});
			}

			if(this.AUPrsLayerInfo){
				let isVisible = false;
				if(this.AUPrsLayerUtils){
					isVisible = this.AUPrsLayerUtils.isVisible;
					this.AUPrsLayerUtils.clear();
				}
				if(!this.AUPrsLayerUtils){
					this.AUPrsLayerUtils = this.addCommonLayer(this.AUPrsLayerInfo);
				}
				if(isVisible){
					this.AUPrsLayerUtils.showLayer();
				}
			}

			if(this.RNPrsLayerInfo){
				if(!this.RNPrsLayerUtils){
					this.RNPrsLayerUtils = this.addCommonLayer(this.RNPrsLayerInfo);
				}
			}

			if(this.RDPrsLayerInfo){
				if(!this.RDPrsLayerUtils){
					this.RDPrsLayerUtils = this.addCommonLayer(this.RDPrsLayerInfo);
				}
			}
		},
		handlePointerClick(map, layer){
			map.on("mouseenter", layer.id + "_Id", function(){
				map.getCanvas().style.cursor = "pointer";
			});
			map.on("mouseleave", layer.id + "_Id", function(){
				map.getCanvas().style.cursor = "";
			});

			map.on("click", layer.id + "_Id", async e => {
				const gid = e.features[0].properties.gid;
				const itemData = await this.$axios.get(
					"coring-map-layers/" + layer.id + "/data/" + gid,
					{showLoader: false}
				);
				let infos = itemData.data;
				const coordinates = infos.coordinates;

				// Create the popup
				const MapPopupClass = Vue.extend(MapPopup);
				this.popup = new mapboxgl.Popup({offset: 25})
				.setHTML("<div id=\"vue-popup-content\"></div>")
				.setLngLat([coordinates[0], coordinates[1]])
				.addTo(this.map);
				const popupInstance = new MapPopupClass({
					propsData: {
						coreData: infos,
						campaignId: this.coringCampaign.id
					}
				});

				this.popup._closeButton.style.top = "10px";
				this.popup._closeButton.style.right = "10px";
				this.popup._closeButton.className = "mdi mdi-close popup-close-button";
				this.popup._closeButton.innerHTML = "";
				popupInstance.$mount("#vue-popup-content");
				this.popup._update();

				this.currentCoreId = infos.id;
				this.isDiaporamaButtonDisplayed =
          this.coringCampaign.status !== "awaiting_validation" &&
          this.coringCampaign.status !== "programing_validated";
				if(this.isDiaporamaOpen){
					this.isDiaporamaOpen = this.isDiaporamaButtonDisplayed;
				}
			});

			map.on("click", () => {
				this.isDiaporamaButtonDisplayed = this.isDiaporamaOpen;
				this.$emit("closeMenu", false);
			});
		},
		handleLabelPointerClick(map, layer){
			if(!layer.displayColumn){
				return;
			}
			map.on("mouseenter", this.getLabelLayerId(layer), function(){
				map.getCanvas().style.cursor = "pointer";
			});
			map.on("mouseleave", this.getLabelLayerId(layer), function(){
				map.getCanvas().style.cursor = "";
			});

			map.on("click", this.getLabelLayerId(layer), async() => {
				this.switchDisplayDiapoBtn(false);
			});

			map.on("click", () => {
				this.isDiaporamaButtonDisplayed = this.isDiaporamaOpen;
				this.$emit("closeMenu", false);
			});
		},
		async loadPhotos(itemData, imageSettings){
			if(imageSettings.length === 0){
				this.isDiaporamaButtonDisplayed = false;
				return;
			}
			let id = itemData[imageSettings[0].dstMappedColumnName].val;
			this.photosData = await this.$api.images.findBySettingsAndId(
				imageSettings[0].id,
				id
			);
			this.isDiaporamaButtonDisplayed =
        Object.keys(this.photosData).length !== 0;
		},
		getDataLayersIdList(layers){
			let layersIdList = [];
			layers.forEach(layer => {
				if(!layer.isContainer){
					layersIdList.push(layer.id);
				}
				else {
					let childLayerIdList = this.getDataLayersIdList(layer.children);
					layersIdList.push(...childLayerIdList);
				}
			});
			return layersIdList;
		},
		getVisibleDataLayersIdList(layers){
			let layersIdList = [];
			this.selectedLayers.forEach(selectedLayer => {
				layers.forEach(layer => {
					if(!layer.isContainer){
						let layerId = this.getLayerId(layer);
						if(selectedLayer.indexOf(layerId) !== -1){
							layersIdList.push(layer.id);
						}
					}
					else {
						let childLayerIdList = this.getVisibleDataLayersIdList(
							layer.children
						);
						layersIdList.push(...childLayerIdList);
					}
				});
			});
			return layersIdList;
		},
		reload3DLayers(layers){
			let zoomLevel = this.map.getZoom();
			let reload3DLayer = layer => {
				if(
					layer.isContainer ||
          layer.status !== MAPLAYER_STATUS.GENERATION_FINISHED
				){
					return;
				}
				if(layer.geomType == MAPLAYER_TYPE.POLYGON3D){
					let currentMapLayerId = layer.id + "_Id";
					let currentMapLayer = this.map.getLayer(currentMapLayerId);
					let layerVisibility = this.map.getLayoutProperty(
						currentMapLayerId,
						"visibility"
					);
					let layerTileSource = currentMapLayer.sourceLayer;
					if(
						currentMapLayer &&
            layerVisibility === "visible" &&
            ((layerTileSource == "3D" &&
              zoomLevel < MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL) ||
              (((layerTileSource == "linestring") || (layerTileSource == "polygon")) &&
                zoomLevel >= MAPLAYER_ZOOM.SWITCH_2D_3D_LAYER_ZOOM_LEVEL))
					){
						this.removeLayer(layer);
						this.addMapLayer(layer);
						this.filters.forEach(el => {
							if(el.id === currentMapLayerId){
								this.map.setFilter(el.id, ["any", ...el.filters]);
							}
						});
					}
				}
			};
			layers.forEach(layer => {
				if(layer.isContainer){
					this.reload3DLayers(layer.children);
				}
				else {
					reload3DLayer(layer);
				}
			});
		},
		resetLayers(){
			let that = this;
			this.map.getCanvas().style.cursor = "wait";
			this.initLayers(that.layersList).then(() => {
				that.mapLoaded = true;
				that.map.getCanvas().style.cursor = "";
				let visibleLayers = that.getVisibleDataLayersIdList(that.layersList);
				if(visibleLayers.length == 0){
					visibleLayers = that.getDataLayersIdList(that.layersList);
				}
				that.updateLayerBounds(visibleLayers, true);
			});
		},
		styleChanged(){},
		switchDisplayDiapoBtn(newValue){
			let canvas = this.map.getCanvas();
			if(!newValue){
				this.isDiaporamaOpen = false;
				canvas.style.width = "100%";
			}
			else {
				this.isDiaporamaOpen = true;
				canvas.style.width = "100%";
			}
			setTimeout(() => this.map.resize(), 10);
		},
		updateLayers(newLayersList, layers){
			let updateLayer = layer => {
				let visibility = "none";
				let layerId = this.getLayerId(layer);
				if(newLayersList.indexOf(layerId) != -1){
					visibility = "visible";
				}
				this.map.setLayoutProperty(layerId, "visibility", visibility);
				if(layer.displayColumn){
					let labelLayerId = this.getLabelLayerId(layer);
					this.map.setLayoutProperty(labelLayerId, "visibility", visibility);
				}
			};
			layers.forEach(layer => {
				if(!layer.isContainer){
					updateLayer(layer);
				}
				else {
					this.updateLayers(newLayersList, layer.children);
				}
			});
		}
	},
	mounted(){
		this.mapInit();
		this.$root.$on("flyTo", this.flyTo);
		this.$root.$on("closeCurrent", () => {
			if(this.popup){
				this.popup.closeFunc();
				this.popup = null;
			}
		});
	},
	beforeDestroy(){
		this.map.remove();
	}
};
</script>

<style lang="scss">
#mapContainer {
  overflow: hidden;
}
#Auscultation_Map {
  height: 85vh;
}

.auscultation_map {
  width: 100%;
  height: 85vh;
}
.geocoder {
  position: absolute;
  z-index: 1;
  width: 50%;
  left: 45%;
  top: 10px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 10%;
}
.bottom-left-gizmo {
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 1;

  .distance-container > * {
    background-color: #fff;
    font-size: 16px;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 3px;
  }
}

.auscultation_map_diapo_on {
  width: 50% !important;
  height: 85vh;
  right: 0;
  position: absolute;
}
.diaporama_map_diapo_on {
  width: 50%;
  height: 85vh;
  left: 0;
  position: absolute;
  z-index: 1;
}
.diaporama_map_diapo_off {
  display: none !important;
}
.diaporama_btn {
  font-family: "Roboto";
  transform: rotate(-90deg);
  position: absolute;
  left: 0px;
  top: 65%;
  z-index: 1;
  transform-origin: 0% 0;
}
.mapboxgl-canvas {
  outline: none;
}
</style>
